<template>
	<div class="news-list">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<!-- 导航 -->
		<navigation>
			<template slot="breadcrumb">
				<breadcrumb title="资讯列表" routerPath="/newslist" :subtitle="article.title"></breadcrumb>
			</template>
		</navigation>
		<div class="wrapper news-list-con clearfix">
			<div class="news-list-left news-details">
				<div class="news-header">
					<div class="title">{{article.title}}</div>
					<p><!-- <span><i class="el-icon-user"></i>admin</span> --><span><i class="el-icon-view"></i>{{article.show_views}}人浏览</span></p>
				</div>
				<div class="content" v-html="article.content">
					
				</div>
			</div>
			<div class="news-list-right">
				<hotSale></hotSale>
			</div>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as ArticleApi from '@/api/article'
	import mainHeader from '../../components/header.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	import mainFooter from '../../components/footer.vue'
	import hotSale from '../product-details/components/hot-sale.vue'
	export default {
		components: {
			mainHeader,
			navigation,
			breadcrumb,
			mainFooter,
			hotSale
		},
		data() {
			return {
				articleId: 0,
				article: {}
			};
		},
		created() {
			if(this.$route.query.id){
				this.articleId = this.$route.query.id
			}
			this.getArticle()
		},
		methods: {
			getArticle(){
				let app = this
				ArticleApi.detail(app.articleId).then((result)=>{
					// console.log(result)
					app.article = result.data.detail
				})
			}
		}
	
	}
</script>

<style lang="scss">
	@import "../../assets/css/news.scss";
	@import "../../assets/css/product-details.scss";
</style>
